<template>
  <v-row justify="center">
      <v-dialog v-model="show"  max-width="700px" persistent>
        <v-card>
          <v-card-title class="">
          <v-flex class="d-flex justify-end">
            <v-btn 
            large 
            color="grey"
            icon
            @click="$emit('close')"
            >
            <v-icon>
             mdi-close-circle
            </v-icon>
            </v-btn>
          </v-flex>
          </v-card-title>
          <v-card-text>
            <div class="text-center">
              <img
                src="@/assets/img/reset-password.png"
                width="70px"
                height="70px"
                alt="One Box"
              />
            </div>
            <!-- {{data.redirect}} -->
            <div class="text-center" style="font-size: 13px;color:#333333">
              <h2 v-if="$t('default') === 'th'">รหัสผ่านหมดอายุ</h2>
              <h2 v-else>Password Expired</h2>
            </div>
            <div class="text-center">
              <br />
              <span v-if="$t('default') === 'th'" style="font-size: 16px;" class="ml-4">
                บริษัท <b>{{ ' ' + data.business_name_th + ' '}}</b> ที่คุณสังกัดอยู่มีการตั้งค่าวันหมดอายุของรหัสผ่านไว้ทุกๆ <b>{{' ' + data.password_expired + ' '}}</b> วัน
              </span>
              <span v-else style="font-size: 16px;" class="ml-4">
                Company <b>{{ ' ' + data.business_name_eng + ' '}}</b> that you belong to There is a setting to change your password every <b>{{' ' + data.password_expired + ' '}}</b> day(s).
              </span> 
              <br/>
            </div>
            <div class="text-center mt-5">
                <span v-if="$t('default') === 'th'" style="font-size: 16px;">
                คุณเปลี่ยนรหัสผ่านล่าสุดเมื่อ : <b>{{data.password_last_changed === 'Never' ? 'ไม่เคยเปลี่ยนรหัสผ่าน' : formatdatetime(data.password_last_changed)}}</b>
              </span> 
              <span v-else style="font-size: 16px;">
                Password last changed : <b>{{formatdatetime(data.password_last_changed)}}</b>
              </span> 
            </div>
            <div class="text-center mt-5 mb-5">
                <span v-if="$t('default') === 'th'" style="font-size: 16px;">
                สามารถเปลี่ยนรหัสผ่านได้โดยกดปุ่ม <b>"เปลี่ยนรหัสผ่าน"</b>
              </span> 
              <span v-else style="font-size: 16px;">
                Can change your password by pressing the button. <b>"Change password"</b>
              </span> 
            </div>
            <v-divider></v-divider>
            <div class="text-center mt-5">
              <v-btn
                v-if="$t('default') === 'th'"
                outlined
                color="#259B71"
                @click="change_password()"
              >
              <v-icon class="mr-1">
                mdi-lock-reset
              </v-icon>
                เปลี่ยนรหัสผ่าน
              </v-btn>
              <v-btn
                v-else
                outlined
                color="#259B71"
                @click="change_password()"
              >
              <v-icon class="mr-1">
                mdi-lock-reset
              </v-icon>
                Change password
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
  </v-row>
</template>
<script>
import VueCookies from "vue-cookies";
export default {
  props: ["show","data"],
  data: function() {
    return {
      checkshow: false,
      dialog: false,
    }
  },
  methods:{
    formatdatetime(_datetime) {
      if (
        _datetime === "" ||
        _datetime === "-" ||
        _datetime === undefined ||
        _datetime === null
      ) {
        return "-";
      } else {
        let dateyear =
          _datetime.split("")[0] +
          _datetime.split("")[1] +
          _datetime.split("")[2] +
          _datetime.split("")[3];
        let datemonth = _datetime.split("")[5] + _datetime.split("")[6];
        let dateday = _datetime.split("")[8] + _datetime.split("")[9];
        let hour = _datetime.split("")[11] + _datetime.split("")[12];
        let minute = _datetime.split("")[14] + _datetime.split("")[15];
        let second = _datetime.split("")[17] + _datetime.split("")[18];

        return (
          dateday +
          "-" +
          datemonth +
          "-" +
          dateyear +
          " " +
          this.$t('admin.time') +
          " " +
          hour +
          ":" +
          minute 
          +
          ":" +
          second
        );
      }
    },
    change_password(){
        this.$emit('close');
        window.open(this.data.redirect)
    }
  }
}
</script>